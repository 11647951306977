import React, { useState, useEffect } from "react";
import "./NFTCard.css";
import AddSign from "../../assets/AddSign.png";
import AddedSign from "../../assets/AddedSign.png";

function NFTCard({
  token,
  nftView,
  firstNFT,
  setFirstNFT,
  secondNFT,
  setSecondNFT,
  count,
  setCount,
}) {
  const [sign, setSign] = useState(AddSign);

  useEffect(() => {
    setFirstNFT(null);
    setSecondNFT(null);
  }, []);

  const handleTokenClicked = (evt) => {
    console.log(count);
    //select
    if (!(token.tokenId === firstNFT?.tokenId) && count === 0) {
      setFirstNFT(token);
      setCount(count + 1);
      setSign(AddedSign);
    } else if (
      !(token.tokenId === firstNFT?.tokenId) &&
      count === 1 &&
      secondNFT === null
    ) {
      setSecondNFT(token);
      setCount(count + 1);
      setSign(AddedSign);
    }
    //unselect
    else if (token.tokenId === firstNFT?.tokenId && !(count === 0)) {
      setFirstNFT(null);
      setCount(count - 1);
      setSign(AddSign);
    } else if (
      !(token.tokenId === firstNFT?.tokenId) &&
      token.tokenId === secondNFT?.tokenId &&
      count !== 0
    ) {
      setSecondNFT(null);
      setCount(count - 1);
      setSign(AddSign);
    } else if (
      count === 1 &&
      secondNFT !== null &&
      firstNFT === null &&
      token.tokenId !== firstNFT?.tokenId &&
      token.tokenId !== secondNFT?.tokenId
    ) {
      setFirstNFT(token);
      setCount(count + 1);
      setSign(AddedSign);
    }
    // console.log("firstNFT", firstNFT);
    // console.log("secondNFT", secondNFT);

    // console.table([
    //   {
    //     Number: count,
    //     First_NFT_Name: firstNFT,
    //     Count: count,
    //     Second_NFT_Name: secondNFT,
    //   },
    // ]);
  };

  let selectedImage = "";
  if (
    token.tokenId === firstNFT?.tokenId ||
    token.tokenId === secondNFT?.tokenId
  ) {
    selectedImage = "selectedImage";
  }

  console.log(token);

  return (
    <div className={`NFTCard ${selectedImage}`} onClick={handleTokenClicked}>
      <div className="NFTCard-Container">
        <img
          src={
            nftView === "evolved" ? token.evolved_thumb : token.genesis_thumb
          }
          alt="NFT"
          className="NFT-Image"
        />
        <p className="Nft-Id">Fiendz #{token.tokenId}</p>
        <div className={sign === AddSign ? "addSign" : "addedSign"}>
          <img src={sign} alt="" />
        </div>
      </div>
    </div>
  );
}

export default NFTCard;
