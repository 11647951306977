
const options = {
  Body: [
    { check: ["Camera Necklace", "Cat Chain"], add: "Golden Camera" },
    { check: ["White Tank Top", "77 Jersey"], add: "Baseball Jersey" },
    {
      check: ["Big Puffer Jacket", "Pink Flamboyant Jacket"],
      add: "Pink Fur Coat",
    },
    {
      check: ["Big Puffer Jacket", "Black Jacket"],
      add: "Black Fur Coat",
    },
    {
      check: ["Big Puffer Jacket", "Red Jacket"],
      add: "Red Fur Coat",
    },
    {
      check: ["Black Belt", "Black Hoodie"],
      add: "FFF11B Suit",
    },
    {
      check: ["Fluid", "Camo Hoodie"],
      add: "Bulletproof Vest",
    },
    {
      check: ["Aviator Jacket", "Mushrooms"],
      add: "Angel Wings",
    },
    {
      check: ["White Tank Top", "Prison Uniform"],
      add: "B&W Prison Suit",
    },
    {
      check: ["Cacti", "Fluid"],
      add: "Gourd",
    },
    {
      check: [null, null],
      add: "Nipples",
    },
    {
      check: ["Light Blue Jacket", "Red Jacket"],
      add: "Purple Jacket",
    },
    {
      check: ["Pink Jacket", "Red Jacket"],
      add: "Orange Jacket",
    },
    {
      check: ["Light Blue Jacket", "Black Jacket"],
      add: "Navy Blue Jacket",
    },
    {
      check: ["Backpack", "Blue T Shirt"],
      add: "Overalls",
    },
    {
      check: ["Vacation Shirt", "Cacti"],
      add: "Blue Tropical Shirt",
    },
    {
      check: ["Vacation Shirt", "Cacti"],
      add: "Pink Tropical Shirt",
    },
    {
      check: ["Vacation Shirt", "Cacti"],
      add: "Red Tropical Shirt",
    },
    {
      check: ["Suit with Red Tie", "Blue Hoodie"],
      add: "Stone College Sweater",
    },
    {
      check: ["Suit with Red Tie", "Camo Hoodie"],
      add: "Green College Sweater",
    },
    {
      check: ["Suit with Red Tie", "Red Hoodie"],
      add: "Maroon College Sweater",
    },
    {
      check: ["Pink Hoodie", "Pink Jacket"],
      add: "Knitted Sweater",
    },
    {
      check: ["77 Jersey", "Red Jacket"],
      add: "Fiendz Varsity Jacket",
    },
    {
      check: ["White Tank Top", "Pink Hoodie"],
      add: "Baseball Tee",
    },
    {
      check: ["Satchel", "ATM"],
      add: "Nip Crossbody",
    },
    {
      check: ["Barbed Wire Belt", "Prison Uniform"],
      add: "Tatted Body",
    },
    {
      check: ["Lumberjack Clothes", "Plaid Coat"],
      add: "Color Block Hoodie",
    },
  ],
  Face: [
    { check: ["Heart Rate", "Cyborg Eyes"], add: "Green Laser Vision" },
    { check: ["Tattered", "Teary"], add: "Tatted" },
    { check: ["Nervous", "Angry"], add: "Smirk" },
    { check: ["Eyelashes Sad", "Confused"], add: "Pouty" },
    { check: ["Drool", "Whine"], add: "Anime Face" },
    { check: ["Falling Tears", "Fierce"], add: "Permanent Teardrops" },
    { check: ["Tattered", "Fancy Monocle"], add: "Stitched Eye" },
    { check: ["Alien", "Green Alien"], add: "Acid Face" },
    { check: ["Smokin a J", "Smokin a J"], add: "Cross Jay" },
    { check: ["Animatronic", "Dead"], add: "Bonjour" },
    // etc
  ],
  Fur: [
    { check: ["Tortoiseshell", "Black with Spots"], add: "Leopard" },
    { check: ["Tiger", "White"], add: "Snow Tiger" },
    { check: ["Tiger", "Pink"], add: "Pink Tiger" },
  ],
  Hand: [
    {
      check: ["Prison Shank", null],
      add: "Fiendz Claws",
    },
    {
      check: ["Golden Gun", "Green Potion"],
      add: "Golden Ray Gun",
    },
    {
      check: ["Baseball Bat", "Lipstick"],
      add: "Fiendz Mallet",
    },
    {
      check: ["Bag of Catnip", "Bong"],
      add: "Fun Plant",
    },
    {
      check: ["Showerhead", "Pink Balloon"],
      add: "Water Gun",
    },
    {
      check: ["Ham on a Bone", "Green Potion"],
      add: "Teddy Bear",
    },

    {
      check: ["Paintbrush", "Showerhead"],
      add: "Painting Palette",
    },
    {
      check: ["Paintbrush", "Flashlight"],
      add: "Graffiti Can",
    },
    {
      check: ["Ham on a Bone", "Nunchucks"],
      add: "Boxing Gloves",
    },
    {
      check: ["Red Energy Sword", "Red Energy Sword"],
      add: "Blue Energy Sword",
    },
    {
      check: ["Red Energy Sword", "Red Energy Sword"],
      add: "Green Energy Sword",
    },
    {
      check: ["Red Energy Sword", "Red Energy Sword"],
      add: "Purple Energy Sword",
    },
    //ETC ENERGY SWORDS!
  ],
  Head: [
    { check: ["Cast Iron Pan", "Chef Hat"], add: "Melted Cheese" },
    { check: ["Flower Wreath", "Halo"], add: "BIG Crown" },
    { check: ["Lighting Bolt", "Litter"], add: "Fire Head" },
    { check: ["Brain", "Pencil Behind Ear"], add: "Graduation Cap" },
    { check: ["Litter", "Kitten"], add: "The Butt of Kitten" },
    { check: ["Yellow Bow", "Gold Earrings"], add: "Laurel Wreath Crown" },
    { check: ["Party Hat", null], add: "Paper Crown" },
    { check: ["Third Eye", "Litter"], add: "Third Eye Laser" },
    { check: ["Narwhal Horn", "Turtle Shell"], add: "Viking Hat" },
    { check: ["FF Flag", "Yarn Ball"], add: "Bandana" },
    { check: ["Green F Hat", "Ice Cube"], add: "Degen Now Cry Later" },
  ],

  // etc
};

export default options;
