import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./IntroPage.css";

import logo from "../../assets/logo-large.png";
// import logo from "../../assets/logo.png";
import Footer from "../Footer/Footer";
import { BsArrowUpLeft } from "react-icons/bs";
import MobileFooter from "../Footer/MobileFooter";
import useWindowDimensions from "../../hooks/getWindowDimensions";

function IntroPage({ audioRef }) {
  const navigate = useNavigate();
  //const [value, setValue] = useState(0);
  const [percent, setPercent] = useState(0);

  const { width } = useWindowDimensions();

  let progressWidth = 200;
  let progress = percent * progressWidth;

  useEffect(() => {
    audioRef.current.volume = 0;
  }, []);

  async function NextPage() {
    setPercent(1);
    return;
  }

  async function ClickedNextPage() {
    await NextPage();
    if (width > 1150) {
      setTimeout(() => {
        navigate("/retreat");
      }, 1000);
    } else {
      setTimeout(() => {
        navigate("/signIn");
      }, 1000);
    }
  }

  return (
    <div id="IntroPage">
      <div className="IntroPage-Container">
        <img
          className="IntroPage-Logo"
          src={logo}
          alt=""
          onClick={ClickedNextPage}
        ></img>
        <div className="progress-div" style={{ width: progressWidth }}>
          <div style={{ width: `${progress}px` }} className="progress" />
        </div>
        <div className="IntroPage-H4">
          <h4>Click on the Fiend above to Enter The Retreat</h4>
        </div>
      </div>
      <div className="Intro-Footer">
        <MobileFooter />
      </div>
      <Footer audioRef={audioRef}></Footer>
    </div>
  );
}

export default IntroPage;
