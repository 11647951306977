
import EthereumSession from "./eth-session";
import ffABI from "./ffABI";
import truthABI from "./truthABI";

export const CONFIG = {
  chain:         EthereumSession.COMMON_CHAINS[1],
  infuraId:     '9f57294117e34952877d794e30a0fdbe',
  network:      'mainnet',

  ffAddress:    '0xAcfA101ECE167F1894150e090d9471aeE2dD3041',
  ffABI:         ffABI,

  truthAddress: '0x891AEE31551Af994fd215C0c9118D19940A65ADC',
  truthABI:      truthABI,
};

CONFIG.networkUrl = `https://${CONFIG.network}.infura.io/v3/${CONFIG.infuraId}`;


export const ffSession = new EthereumSession({
  contractABI:     CONFIG.ffABI,
  contractAddress: CONFIG.ffAddress,
  chain:           CONFIG.chain
});

export const truthSession = new EthereumSession({
  contractABI:     CONFIG.truthABI,
  contractAddress: CONFIG.truthAddress,
  chain:           CONFIG.chain
});

