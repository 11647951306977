import React from "react";
import { useNavigate } from "react-router-dom";

import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3Modal from "web3modal";

import { CONFIG, ffSession, truthSession } from "../../lib/config";

import "./SignIn.css";

function SignIn({ setWalletAddress, walletAddress }) {
  const navigate = useNavigate();

  const modal = React.useMemo(() => {
    return new Web3Modal({
      network: CONFIG.network,
      cacheProvider: false,
      disableInjectedProvider: false,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: CONFIG.infuraId,
          },
        },
        "custom-walletlink": {
          package: WalletLink,
          display: {
            name: "Coinbase",
            description: "Connect to Coinbase Wallet",
            logo: "/coinbase.jpg",
          },
          options: {
            appName:    "Coinbase",
            chainId:    CONFIG.chain.decimal,
            networkUrl: CONFIG.networkUrl
          },
          connector: async () => {
            const walletLink = new WalletLink({ appName: 'Coinbase' });
            const provider = walletLink.makeWeb3Provider(CONFIG.networkUrl, CONFIG.chain.decimal);
            await provider.enable();
            return provider;
          }
        }
      },
    });
  }, []);

  const connectWallet = async (deep) => {
    let provider;
    if( typeof window !== 'undefined' )
      provider = window.ethereum;

    try{
      if(deep){
        provider = await modal.connect();
      }
    }
    catch( err ){
      if( err === 'Modal closed by user' && err.code === 4001 ){
        //no-op
      }
      else{
        console.warn({ err });
        alert('Connection failed, please try again');
      }

      return false;
    }

    if (await ffSession.connectWeb3(deep, provider)) {
      await truthSession.connectWeb3(deep, provider);
      const account = ffSession.wallet.accounts[0];
      console.log(account);
      setWalletAddress(account);
    }
  };

  const getNFTData = async () => {
    if (walletAddress) {
      let balance = await ffSession.contract.methods
        .balanceOf(walletAddress)
        .call();
      balance = parseInt(balance.toString());

      if (balance >= 0) {
        // DELETE THE =
        navigate("/dashboard");
      }
    }
  };

  React.useEffect(() => {
    getNFTData();
  }, [walletAddress]);

  return (
    <div id="SignIn">
      <div className="SignIn-Container">
        <h1>SIGN IN</h1>

        <button onClick={connectWallet}>CONNECT</button>
      </div>
    </div>
  );
}

export default SignIn;
