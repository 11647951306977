import React, { useState, useEffect } from "react";
import "./NFTPicked.css";

import HandImage from "../../assets/HandImage.png";
import HeadImage from "../../assets/HeadImage.png";
import FaceImage from "../../assets/FaceImage.png";
import BackgroundImage from "../../assets/BackgroundImage.png";
import FurImage from "../../assets/FurImage.png";
import BodyImage from "../../assets/BodyImage.png";
import TraitSelector from "../TraitSelector/TraitSelector";
import px from "../../assets/px.gif";

function NFTPicked({ firstNFT, secondNFT, layers, setLayers }) {
  const [token1, setToken1] = useState(null);
  const [token2, setToken2] = useState(null);

  const [background, setBackground] = useState(null);
  const [fur, setFur] = useState(null);
  const [body, setBody] = useState(null);
  const [face, setFace] = useState(null);
  const [head, setHead] = useState(null);
  const [hand, setHand] = useState(null);
  const [bodyHand, setBodyHand] = useState(null);

  const [defLayerState, setDefLayerState] = useState({});

  const fetchLayer = async (feature, expression) => {
    const url =
      `https://fiendz.herodevelopment.com/trait.php?feature=${feature}&expression=` +
      encodeURIComponent(expression);
    const response = await fetch(url);
    if (response.ok) {
      const layer = await response.json();
      return layer;
    } else {
      console.warn(`Missing layer ${feature}::${expression}`);
      return null;
    }
  };

  const fetchToken = async (tokenId) => {
    const url = `https://fiendz.herodevelopment.com/token.php?tokenId=${tokenId}`;
    const response = await fetch(url);
    if (response.ok) {
      // console.log(`ok(${tokenId})`);

      return response.json();
    } else {
      // console.log(`bad(${tokenId})`);
      throw new Error(`Failed to fetch token with id ${tokenId}`);
    }
  };

  const handleFur = async (furLayer) => {
    //TODO: furLayer.value = "Black with Spots";

    if (furLayer) {
      setFur(furLayer);

      if (furLayer?.value && hand?.value) {
        if(hand.value !== "Boxing Gloves"){
          fetchLayer("Body Hand", furLayer.value).then(setBodyHand);
          return
        }
      }

      // else
      setBodyHand(null);
    } else {
      setFur(null);
      setBodyHand(null);
    }
  };

  const handleHand = (handLayer) => {
    if (handLayer) {
      setHand(handLayer);

      if (fur?.value && handLayer?.value) {
        if(handLayer.value !== "Boxing Gloves"){
          fetchLayer("Body Hand", fur.value).then(setBodyHand);
          return;
        }
      }

      // else
      setBodyHand(null);
    } else {
      setHand(null);
      setBodyHand(null);
    }
  };

  const handleInit = async () => {
    console.log("Init Starting");
    if (token1 === null && firstNFT?.tokenId) {
      await fetchToken(firstNFT.tokenId).then(setToken1);
    }

    if (token2 === null && secondNFT?.tokenId) {
      await fetchToken(secondNFT.tokenId).then(setToken2);
    }
  };

  const loadDefaultLayers = async (token) => {
    const layers = await Promise.all(
      token.attributes.map((attr) => fetchLayer(attr.trait_type, attr.value))
    );

    const state = {};
    layers.forEach((layer) => {
      state[layer.trait_type] = layer;
    });

    if (state.Hand && state.Fur) {
      state["Body Hand"] = await fetchLayer(
        "Body Hand",
        fur?.expression || state.Fur.expression
      );
    }

    setDefLayerState(state);
  };

  const selectBodyHandLayer = () => {
    if(hand){
      if(hand.expression){
        if(hand.expression !== "Boxing Gloves")
          return bodyHand?.path || defLayerState?.["Body Hand"]?.path || px;
      }
    }
    else if(defLayerState?.["Body Hand"]){
      return defLayerState?.["Body Hand"]?.path || px;
    }

    return px;
  };

  const selectHeadExpression = () => {
    if(head)
      return head.expression;

    if(defLayerState?.Head)
      return defLayerState.Head.expression;

    return null;
  };

  React.useEffect(() => {
    handleInit();
  }, []);

  React.useEffect(() => {
    if (token1) {
      loadDefaultLayers(token1);
    }
  }, [token1, fur]);

  // Continue Button and SEND METADATA
  React.useEffect(() => {
    const allLayers = [background, fur, body, face, head, hand, bodyHand];

    const newLayers = { ...layers };
    allLayers.forEach((layer) => {
      if (layer) {
        newLayers[layer.feature] = layer;
      }
    });

    console.log(newLayers);
    setLayers(newLayers);
  }, [background, fur, body, face, head, hand, bodyHand]);

  //Continue BUTTON

  return (
    <div className="NFTPicked">
      <div className="NFTPicked-Container">
        <div className="NFTPicked-Container-Image">
          {/* Evolved Display */}
          <div className="NFTPicked-Container-ImgContainer-Left">
            <img
              className="NFTPicked-Container-Image-Left-Background"
              src={background?.path || defLayerState.Background?.path || px}
              alt=""
            />

            {selectHeadExpression() === 'Meow Cola Hat' &&
              <div>
                <img
                  className="NFTPicked-Container-Image-Left-Fur"
                  src="https://fiendz.herodevelopment.com/layers/evolved/Head/Meow Cola Hat Back.png"
                  alt=""
                />
              </div>
            }

            <div>
              <img
                className="NFTPicked-Container-Image-Left-Fur"
                src={fur?.path || defLayerState?.Fur?.path || px}
                alt=""
              />
            </div>

            <div>
              <img
                className="NFTPicked-Container-Image-Left-Fur"
                src="https://fiendz.herodevelopment.com/layers/evolved/Base.png"
                alt=""
              />
            </div>

            <div>
              <img
                className="NFTPicked-Container-Image-Left-Body"
                src={body?.path || defLayerState?.Body?.path || px}
                alt=""
              />
            </div>

            <div>
              <img
                className="NFTPicked-Container-Image-Left-Face"
                src={face?.path || defLayerState?.Face?.path || px}
                alt=""
              />
            </div>

            <div>
              <img
                className="NFTPicked-Container-Image-Left-Head"
                src={head?.path || defLayerState?.Head?.path || px}
                alt=""
              />
            </div>
            <div>
              <img
                className="NFTPicked-Container-Image-Left-Hand"
                src={hand?.path || defLayerState?.Hand?.path || px}
                alt=""
              />
            </div>

            <div>
              <img
                className="NFTPicked-Container-Image-Left-Hand"
                src={selectBodyHandLayer()}
                alt=""
              />
            </div>
          </div>

          {/* Genesis Display */}
          <div className="NFTPicked-Container-ImgContainer-Right">
            <div className="NFTPicked-Container-Image-Right">
              <img
                className="NFTPicked-Container-Image-Right-Background"
                src={
                  background?.genesis_path ||
                  defLayerState?.Background?.genesis_path ||
                  px
                }
                alt=""
              />

              {selectHeadExpression() === 'Meow Cola Hat' &&
                <div>
                  <img
                    className="NFTPicked-Container-Image-Right-Fur"
                    src="https://fiendz.herodevelopment.com/layers/genesis/Head/Meow Cola Hat Back.png"
                    alt=""
                  />
                </div>
              }

              <div>
                <img
                  className="NFTPicked-Container-Image-Right-Fur"
                  src={
                    fur?.genesis_path || defLayerState?.Fur?.genesis_path || px
                  }
                  alt=""
                />
              </div>

              <div>
                <img
                  className="NFTPicked-Container-Image-Left-Fur"
                  src="https://fiendz.herodevelopment.com/layers/genesis/Base.png"
                  alt=""
                />
              </div>

              <div>
                <img
                  className="NFTPicked-Container-Image-Left-Body"
                  src={
                    body?.genesis_path ||
                    defLayerState?.Body?.genesis_path ||
                    px
                  }
                  alt=""
                />
              </div>
              <div>
                <img
                  className="NFTPicked-Container-Image-Left-Face"
                  src={
                    face?.genesis_path ||
                    defLayerState?.Face?.genesis_path ||
                    px
                  }
                  alt=""
                />
              </div>
              <div>
                <img
                  className="NFTPicked-Container-Image-Left-Head"
                  src={
                    head?.genesis_path ||
                    defLayerState?.Head?.genesis_path ||
                    px
                  }
                  alt=""
                />
              </div>
              <div>
                <img
                  className="NFTPicked-Container-Image-Left-Hand"
                  src={
                    hand?.genesis_path ||
                    defLayerState?.Hand?.genesis_path ||
                    px
                  }
                  alt=""
                />
              </div>

              <div>
                <img
                  className="NFTPicked-Container-Image-Left-Fur"
                  src="https://fiendz.herodevelopment.com/layers/genesis/Overlay.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        {token1 && token2 && (
          <>
            <TraitSelector
              icon={BackgroundImage}
              traitType="Background"
              token1={token1}
              token2={token2}
              onSelect={setBackground}
            />

            <TraitSelector
              icon={FurImage}
              traitType="Fur"
              token1={token1}
              token2={token2}
              onSelect={handleFur}
            />
            <TraitSelector
              icon={BodyImage}
              traitType="Body"
              token1={token1}
              token2={token2}
              onSelect={setBody}
            />
            <TraitSelector
              icon={FaceImage}
              traitType="Face"
              token1={token1}
              token2={token2}
              onSelect={setFace}
            />
            <TraitSelector
              icon={HeadImage}
              traitType="Head"
              token1={token1}
              token2={token2}
              onSelect={setHead}
            />
            <TraitSelector
              icon={HandImage}
              traitType="Hand"
              token1={token1}
              token2={token2}
              onSelect={handleHand}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default NFTPicked;
