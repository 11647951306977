import React from "react";

import HubspotForm from "react-hubspot-form";
import "./HubSpotForm.css";

function HubSpotForm() {
  return (
    <div className="HubSpotForm">
      <HubspotForm
        portalId="23780127"
        formId="d9e70ec4-f69e-484d-a109-1d2a00a6e8e9"
        onSubmit={() => console.log("Submit!")}
        onReady={(form) => console.log("Form ready!")}
        loading={<div>Loading...</div>}
      />
    </div>
  );
}

export default HubSpotForm;
