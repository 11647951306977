import React from "react";

import DownArrow from "../../assets/DownArrow.png";
import UpArrow from "../../assets/UpArrow.png";
import px from "../../assets/px.gif";
import options from "../../lib/options";

const noneLayer = {
  z: -1,
  feature: "X",
  expression: null,
  mils: 0,
  is_metadata: 1,
  display_type: "string",
  trait_type: "X",
  value: null,
  path: "/px.gif",
  evolved_path: "/px.gif",
  genesis_path: "/px.gif",
};

const TraitSelector = ({ icon, traitType, token1, token2, onSelect }) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const [layer1, setLayer1] = React.useState(null);
  const [layer2, setLayer2] = React.useState(null);
  const [layer3, setLayer3] = React.useState(null);
  const [selectedLayer, setSelectedLayer] = React.useState(null);

  const fetchLayer = async (attr) => {
    const url =
      `https://fiendz.herodevelopment.com/trait.php?feature=${attr.trait_type}&expression=` +
      encodeURIComponent(attr.value);
    const response = await fetch(url);
    if (response.ok) {
      const layer = await response.json();
      return layer;
    } else {
      debugger;
      console.warn(`Missing layer ${attr.trait_type}::${attr.value}`);
      return {
        ...noneLayer,
        feature: traitType,
        trait_type: traitType,
      };
    }
  };

  const handleSelection = (evt, idx) => {
    if (idx === 1) {
      setSelectedLayer(layer1);
      onSelect(layer1);
    } else if (idx === 2) {
      setSelectedLayer(layer2);
      onSelect(layer2);
    } else if (idx === 3) {
      setSelectedLayer(layer3);
      onSelect(layer3);
    } else {
      console.warn(`Unsupported: idx(${idx})`);
      debugger;
    }
  };

  const handleToggle = (evt) => {
    setExpanded((isExpanded) => !isExpanded);
  };

  React.useEffect(() => {
    console.log(`loading ${traitType}`);

    let attr1 = {
      trait_type: traitType,
      value: null,
    };
    if (layer1 === null && token1?.attributes?.length) {
      attr1 = token1.attributes.find((attr) => {
        if (attr.trait_type === traitType) return attr;
      }) ?? attr1;

      if (attr1.value)
        fetchLayer(attr1).then(setLayer1);
      else
        setLayer1({
          ...noneLayer,
          feature: traitType,
          trait_type: traitType,
        });
    }
  

    let attr2 = {
      trait_type: traitType,
      value: null,
    };
    if (layer2 === null && token2?.attributes?.length) {
      attr2 = token2.attributes.find((attr) => {
        if (attr.trait_type === traitType) return attr;
      }) ?? attr2;

      if (attr2.value)
        fetchLayer(attr2).then(setLayer2);
      else
        setLayer2({
          ...noneLayer,
          feature: traitType,
          trait_type: traitType,
        });
    }


    if (layer3 === null && options[traitType]) {
      const matches = options[traitType].filter((opt) => {
        if (attr1.value === opt.check[0] && attr2.value === opt.check[1])
          return true;
        else if (attr1.value === opt.check[1] && attr2.value === opt.check[0])
          return true;
        else return false;
      });

      if(matches?.length){
        const index = Math.floor(Math.random() * matches.length);
        const attr3 = {
          trait_type: traitType,
          value: matches[index].add,
        };

        if (attr3) {
          fetchLayer(attr3).then(setLayer3);
        }
      }
    }
  }, []);

  return (
    <div className="NFTPicked-Container-Hand" onClick={handleToggle}>
      <div className="NFTPicked-Container-Hand-Header">
        <div className="NFTPicked-Container-Hand-Header-Sub">
          <img src={icon} alt="" />
          <h4>{traitType}</h4>
        </div>
        <div className="NFTPicked-Container-Hand-Header-Sub">
          {selectedLayer ? <p>COMPLETE</p> : <p>INCOMPLETE</p>}
          <img src={isExpanded ? UpArrow : DownArrow} alt="" />
        </div>
      </div>
      <div
        className={
          isExpanded
            ? "NFTPicked-Container-Hand-Options"
            : "NFTPicked-Container-Hand-Options-Small"
        }
      >
        <div
          className="NFTPicked-Container-Hand-Options-Box"
          onClick={(evt) => handleSelection(evt, 1)}
        >
          {traitType !== "Fur" && (
            <img
              className="NFTPicked-Container-Hand-Options-Box-Image-Back"
              src="https://fiendz.herodevelopment.com/layers/evolved/Base.png"
              alt=""
            />
          )}

          <img
            className="NFTPicked-Container-Hand-Options-Box-Image"
            src={layer1?.path || px}
            alt=""
          />

          {traitType === "Fur" && (
            <img
              className="NFTPicked-Container-Hand-Options-Box-Image-Front"
              src="https://fiendz.herodevelopment.com/layers/evolved/Base.png"
              alt=""
            />
          )}

          <h4>{layer1?.value || "None"}</h4>
        </div>
        <div
          className="NFTPicked-Container-Hand-Options-Box"
          onClick={(evt) => handleSelection(evt, 2)}
        >
          {traitType !== "Fur" && (
            <img
              className="NFTPicked-Container-Hand-Options-Box-Image-Back"
              src="https://fiendz.herodevelopment.com/layers/evolved/Base.png"
              alt=""
            />
          )}

          <img
            className="NFTPicked-Container-Hand-Options-Box-Image"
            src={layer2?.path || px}
            alt=""
          />

          {traitType === "Fur" && (
            <img
              className="NFTPicked-Container-Hand-Options-Box-Image-Front"
              src="https://fiendz.herodevelopment.com/layers/evolved/Base.png"
              alt=""
            />
          )}
          <h4>{layer2?.value || "None"}</h4>
        </div>

        {layer3 && (
          <div
            className="NFTPicked-Container-Hand-Options-Box"
            onClick={(evt) => handleSelection(evt, 3)}
          >
            {traitType !== "Fur" && (
              <img
                className="NFTPicked-Container-Hand-Options-Box-Image-Back"
                src="https://fiendz.herodevelopment.com/layers/evolved/Base.png"
                alt=""
              />
            )}

            <img
              className="NFTPicked-Container-Hand-Options-Box-Image"
              src={layer3?.path || px}
              alt=""
            />

            {traitType === "Fur" && (
              <img
                className="NFTPicked-Container-Hand-Options-Box-Image-Front"
                src="https://fiendz.herodevelopment.com/layers/evolved/Base.png"
                alt=""
              />
            )}
            <h4>{layer3.value || "None"} </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default TraitSelector;
