import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Retreat.css";

import IntroVideo from "../../assets/introVideo.mp4";
// import IntroGifNarrow from "../../assets/introVideoNarrow.gif";
// import IntroGifNarrowHq from "../../assets/introVideoNarrowHq.gif";
// import IntroGifNarrowShort from "../../assets/introVideoNarrowShort.gif";
// import IntroGifWide from "../../assets/introVideoWide.gif";

import useWindowDimensions from "../../hooks/getWindowDimensions";

function Retreat() {
  const videoRef = useRef();
  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  useEffect(() => {
    // Only play video if width is greater than 1150
    // Otherwise GIF will be displayed instead using CSS (.Gif-Container)
    if (width > 1150) {
      setTimeout(() => {
        videoRef.current.play();
      }, 1);
    }
  }, [width]);

  const handleVideoEnded = () => {
    navigate("/signIn");
  };

  return (
    <div id="Retreat">
      <div className="Retreat-Container">
        <video muted height="100vh" ref={videoRef} onEnded={handleVideoEnded}>
          <source src={IntroVideo} type="video/mp4" />
        </video>
        {/*<div className="Gif-Container">*/}
        {/*  <img src={IntroGifNarrowShort} />*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default Retreat;
