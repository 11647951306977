import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import HubSpotForm from "./components/HubSpotForm/HubSpotForm.jsx";
import IntroPage from "./components/IntroPage/IntroPage.jsx";
import NFTPick from "./components/NFTPick/NFTPick";
import Retreat from "./components/Retreat/Retreat.jsx";
import SignIn from "./components/SignIn/SignIn";
import retreatSound from "./assets/Retreat.mp3";
import useWindowDimensions from "./hooks/getWindowDimensions";

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [firstNFT, setFirstNFT] = useState(null);
  const [secondNFT, setSecondNFT] = useState(null);
  const audioRef = useRef(null);
  const { width } = useWindowDimensions();

  // Only play audio on desktop
  useEffect(() => {
    if (width > 1150) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [width]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IntroPage audioRef={audioRef} />} />
          <Route path="retreat" element={<Retreat />} />
          <Route
            path="signIn"
            element={
              <SignIn
                setWalletAddress={setWalletAddress}
                walletAddress={walletAddress}
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <Dashboard
                walletAddress={walletAddress}
                setWalletAddress={setWalletAddress}
                setFirstNFT={setFirstNFT}
                firstNFT={firstNFT}
                secondNFT={secondNFT}
                setSecondNFT={setSecondNFT}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="retreatx"
            element={
              <NFTPick
                walletAddress={walletAddress}
                firstNFT={firstNFT}
                secondNFT={secondNFT}
                audioRef={audioRef}
              />
            }
          />
          <Route path="form" element={<HubSpotForm />} />
        </Routes>
        <audio autoPlay ref={audioRef} src={retreatSound} />
      </BrowserRouter>
    </div>
  );
}

export default App;
