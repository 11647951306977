import React from 'react';
import { SiDiscord, SiTwitter } from "react-icons/si";
import { FiInstagram } from "react-icons/fi";
import BodegaLogo from "../../assets/BodegaLOGO.png";
import openSeaLogo from "../../assets/OpenseaLOGO.png";
import FNDZLabsLogo from "../FNDZLabsLogo/FNDZLabsLogo";

export default function MobileFooter() {
  return (
    <div className="Mobile-Footer-Container">
      <div className="Footer-Logo-Right">
        <img
          onClick={() => {
            window.open("https://www.fiendz.io/", "_blank");
          }}
          src={BodegaLogo}
          alt="BodegaLogo"
        />
      </div>
      <div className="Footer-Socials">
        <div className="Socials-container">
          <SiDiscord
            onClick={() => {
              window.open(
                "https://discord.gg/DGpXcvHCEr",
                "_blank"
              );
            }}
            className="social"
            size={30}
          />

          <SiTwitter
            onClick={() => {
              window.open(
                "https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw",
                "_blank"
              );
            }}
            className="social"
            size={30}
          />
          <FiInstagram
            onClick={() => {
              window.open(
                "https://instagram.com/fndzstudios?igshid=YmMyMTA2M2Y=",
                "_blank"
              );
            }}
            className="social"
            size={30}
          />
          <img
            className="Footer-OS-Logo"
            onClick={() => {
              window.open(
                "https://opensea.io/collection/felinefiendznft",
                "_blank"
              );
            }}
            src={openSeaLogo}
            alt=""
          />
        </div>
        <FNDZLabsLogo />
        {/*<div className="DevelopedByFNDZ">*/}
        {/*  <p>Developed by FNDZ Labs</p>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}