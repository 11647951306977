import logo from "../../assets/fndz-labs-logo.png"
import "./FNDZLabsLogo.css";

export default function FNDZLabsLogo() {
  return (
    <div className="FNDZ-Logo-Container">
      <a className="Logo-Link-Wrapper" href="https://twitter.com/fndzlabs" target="_blank" rel="noreferrer noopener">
        <span className="Developed-By-Text">Developed by</span>
        <div className="FNDZ-Squares-Container">
          <img src={logo} alt="fndzlabs" className="FNDZ-Squares" />
        </div>
        <span className="Logo-Name-Text">FNDZ LABS</span>
      </a>
    </div>
  )
}