import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";

import { SiDiscord, SiTwitter } from "react-icons/si";
import { FiInstagram } from "react-icons/fi";
import BodegaLogo from "../../assets/BodegaLOGO.png";
import openSeaLogo from "../../assets/OpenseaLOGO.png";
import retreatSound from "../../assets/Retreat.mp3";

function Footer({ audioRef }) {
  const navigate = useNavigate();

  const [volume, setVolume] = useState(0);


  useEffect(() => {
    let currentVolume = audioRef.current.volume;
    if (currentVolume === 1) {
      audioRef.current.volume = 0;
      setVolume(0);
    } else {
      setVolume(audioRef.current.volume)
    }
  }, [audioRef]);

  const handleVolumeChange = (event) => {
    audioRef.current.play();
    audioRef.current.volume = event.target.value;
    setVolume(event.target.value);
  };
  return (
    <div className="Footer">
      <div className="Footer-Container">
        <div className="Mobile-Container">
          <div className="Footer-Logo-Right">
            <img
              onClick={() => {
                window.open("https://www.fiendz.io/", "_blank");
              }}
              src={BodegaLogo}
              alt="BodegaLogo"
            />
          </div>

          <div className="volumeOption-mobile">
            <p className="volumeAmount">
                <span>Volume %</span>
                <span className="volumeAmount" style={{ color: "yellow" }}>{(volume * 100).toFixed(1)}</span>
            </p>
            <input
              type="range"
              min="0"
              max="1"
              step="0.001"
              onChange={handleVolumeChange}
              value={volume}
              className="volumeSlider"
              style={{
                backgroundColor: "yellow",
              }}
            />
          </div>
        </div>
        <div className="Footer-Info">
          <p>
            Want this implemented into your project?{" "}
            <Link to={"/form"} target="_blank" rel="noreferrer">
              <span>Get Started!</span>
            </Link>
          </p>
          <p>© 2023 Fiendz, LLC. All Right Reserved.</p>
        </div>
        <div className="Social-Volume">
          <div className="Footer-Socials">
            <div className="Socials-container">
              <SiDiscord
                onClick={() => {
                  window.open(
                    "https://discord.gg/DGpXcvHCEr",
                    "_blank"
                  );
                }}
                className="social"
                size={30}
              />

              <SiTwitter
                onClick={() => {
                  window.open(
                    "https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw",
                    "_blank"
                  );
                }}
                className="social"
                size={30}
              />
              <FiInstagram
                onClick={() => {
                  window.open(
                    "https://instagram.com/fndzstudios?igshid=YmMyMTA2M2Y=",
                    "_blank"
                  );
                }}
                className="social"
                size={30}
              />
              <img
                className="Footer-OS-Logo"
                onClick={() => {
                  window.open(
                    "https://opensea.io/collection/felinefiendznft",
                    "_blank"
                  );
                }}
                src={openSeaLogo}
                alt=""
              />
            </div>
            <div className="DevelopedByFNDZ">
              <p>Developed by FNDZ Labs</p>
            </div>
          </div>
          <div className="volumeOption">
            <p>
              <span>Volume %</span>
              <span className="volumeAmountNumber" style={{ color: "yellow" }}>{(volume * 100).toFixed(1)}</span>
            </p>
            <input
              type="range"
              min="0"
              max="1"
              step="0.001"
              onChange={handleVolumeChange}
              value={volume}
              className="volumeSlider"
              style={{
                backgroundColor: "yellow",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
